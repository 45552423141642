.navbar-toggle {
  display: none;
}

.navbar-toggle-link {
  display: block;
  padding: 1px 5px 3px 5px;
  border: none;
  border-bottom: 2px none;
  color: #ffc66d;
  text-decoration: none;
  text-align: center;
  font-size: 24px;
  font-family: 'Roboto Mono', monospace;

  background: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

.navbar-toggle-link:visited {
  color: #ffc66d;
}

.navbar-toggle-link:hover {
  color: #bed6ff;
}

@media screen and (max-width: 655px) {
  .navbar-toggle {
    display: block;
    padding: 5px;
    float: right;
  }
}
