.article-title {
  margin: 0;
  text-align: center;
  font-size: 30px;
  color: #d197d9;
}

.article-content {
  border-top: 1px solid #393939;
  border-bottom: 1px solid #393939;
  padding: 15px;
  margin: 15px 0;
  color: #fff;
}

.article-footer {
  color: #bed6ff;
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
}
