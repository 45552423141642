.progress-bar-background {
  width: 100%;
  height: 4px;
  border-radius: 1px;
}

.progress-bar-foreground {
  width: 10%;
  height: 4px;
  z-index: 1000;
  border-radius: 1px;
}
