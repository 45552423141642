.tag {
  color: #2d2d2d;
  display: inline-block;
  padding: 0.2em;
  border-radius: 0.3em;
}

.tag-sup {
  font-size: 0.4em;
  vertical-align: super;
}

.tag-middle {
  font-size: 0.8em;
  vertical-align: middle;
  margin: 0 0.2em;
}