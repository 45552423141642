.title-h1 {
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
  font-size: 35px;
  color: #d197d9;
}

.title-h2 {
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
  font-size: 30px;
  color: #d197d9;
}

.title-h3 {
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
  font-size: 25px;
  color: #d197d9;
}

.title-h4 {
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
  font-size: 23px;
  color: #d197d9;
}

.title-h5 {
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
  font-size: 21px;
  color: #d197d9;
}

.title-h6 {
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
  font-size: 19px;
  color: #d197d9;
}

