.article-link {
  display: inline-block;
  margin: 5px 25px 5px 0;
  padding: 5px 10px;
  background-color: #f97394;
  color: #fff;
  font-size: 16px;
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
  text-decoration: none;
}


.article-link:visited {
  color: #fff;
}

.article-link:hover {
  color: #bed6ff;
}
