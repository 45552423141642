.main-title {
  margin: 15px 0;
  text-align: center;
  font-family: 'Roboto Slab', serif;
  font-size: 70px;
  text-shadow: 0 0px 10px #2d2d2d;
}

@media screen and (max-width: 620px) {
  .main-title {
    margin: 15px 0;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    font-size: 50px;
    text-shadow: 0 2px 10px #2d2d2d;
  }
}

@media screen and (max-width: 440px) {
  .main-title {
    margin: 15px 0;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    font-size: 30px;
    text-shadow: 0 2px 10px #2d2d2d;
  }
}
