.about-container {
  display: flex;
  flex-wrap: wrap;
}

.about-bio {
  width: 60%;
  padding: 10px;
}

.about-skills {
  width: 40%;
  padding: 10px;
}

.about-spacer {
  margin-top: 25px;
}

@media screen and (max-width: 750px) {
  .about-bio {
    width: 100%;
  }
  
  .about-skills {
    width: 100%;
  }
}
